<template>
  <div>
    <div className="mb-2">
      <a href="javascript: void(0);" className="text-dark font-size-24 font-weight-bold">
        [Feature Request] How to enable custom font that comes from svg #2460
      </a>
    </div>
    <div className="mb-3">
      <a className="font-weight-bold" href="javascript: void(0);"> zxs2162 </a>
      wrote this post 12 days ago · 0 comments
    </div>
    <div className="mb-4">
      <a
        href="javascript: void(0);"
        className="badge text-blue text-uppercase bg-light font-size-12 mr-2"
      >
        Umi
      </a>
      <a
        href="javascript: void(0);"
        className="badge text-blue text-uppercase bg-light font-size-12 mr-2"
      >
        React-framework
      </a>
      <a
        href="javascript: void(0);"
        className="badge text-blue text-uppercase bg-light font-size-12 mr-2"
      >
        Umijs
      </a>
    </div>
    <div>
      <img
        className="img-fluid mb-4 rounded"
        src="resources/images/content/photos/8.jpeg"
        alt="Sea"
      />
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
        consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem ipsum
        dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis consectetur
        corporis esse labore minima molestias, exercitationem consequuntur! Lorem ipsum dolor sit
        amet, consectetur adipisicing elit. Nihil laborum est perferendis consectetur corporis esse
        labore minima molestias, exercitationem consequuntur! Lorem ipsum dolor sit amet,
        consectetur adipisicing elit. Nihil laborum est perferendis consectetur corporis esse labore
        minima molestias, exercitationem consequuntur!
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
        consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem ipsum
        dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis consectetur
        corporis esse labore minima molestias, exercitationem consequuntur!
      </p>
    </div>
  </div>
</template>
